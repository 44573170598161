@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html {
    overflow-y: scroll;
    overflow-x: hidden;
  }

  html,
  body {
    margin: 0;
    padding: 0;
    height: 100%;
    font-family: "Twemoji Country Flags", "Montserrat", sans-serif;
    font-size: 16px;
    background-color: #f6f5f8;
    color: #333333;
  }

  body {
    overscroll-behavior-y: contain;
  }

  h1 {
    display: block;
    font-size: 2em;
    margin-top: 0.67em;
    margin-bottom: 0.67em;
    margin-left: 0;
    margin-right: 0;
    font-weight: bolder;
  }

  h2 {
    display: block;
    font-size: 1.5em;
    margin-top: 0.83em;
    margin-bottom: 0.83em;
    margin-left: 0;
    margin-right: 0;
    font-weight: bolder;
  }

  h3 {
    display: block;
    font-size: 1.17em;
    margin-top: 1em;
    margin-bottom: 1em;
    margin-left: 0;
    margin-right: 0;
    font-weight: bolder;
  }

  h4 {
    display: block;
    margin-top: 1.33em;
    margin-bottom: 1.33em;
    margin-left: 0;
    margin-right: 0;
    font-weight: bolder;
  }

  h5 {
    display: block;
    font-size: 0.83em;
    margin-top: 1.67em;
    margin-bottom: 1.67em;
    margin-left: 0;
    margin-right: 0;
    font-weight: bolder;
  }

  h6 {
    display: block;
    font-size: 0.67em;
    margin-top: 2.33em;
    margin-bottom: 2.33em;
    margin-left: 0;
    margin-right: 0;
    font-weight: bolder;
  }

  p {
    display: block;
    margin-top: 1em;
    margin-bottom: 1em;
    margin-left: 0;
    margin-right: 0;
  }

  ul,
  ol {
    display: block;
    list-style-type: disc;
    margin-top: 1em;
    margin-bottom: 1em;
    margin-left: 0;
    margin-right: 0;
    padding-left: 40px;
  }

  ol {
    list-style-type: decimal;
  }

  ol > li {
    margin-bottom: 15px;
  }

  iframe {
    max-width: 100%;
  }

  input[type="number"]::-webkit-outer-spin-button,
  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"] {
    -webkit-appearance: none;
    margin: 0;
    -moz-appearance: textfield !important;
  }
}

@layer components {
  #__next {
    height: 100%;
    z-index: 1;
  }

  .dynamic-text-black {
    button,
    a,
    span,
    h4,
    div {
      color: black;
    }
  }

  .dynamic-text-white {
    button,
    a {
      color: white;
    }
  }

  .header-gradient {
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 30%, var(--gradient-color) 95%);

    @media (min-width: 768px) {
      background: linear-gradient(180deg, rgba(0, 0, 0, 0) 40%, var(--gradient-color) 95%);
    }
  }

  .description-link {
    a {
      text-decoration: underline;
      transition: 200ms ease-out opacity;
      font-weight: 500;
      color: #4c69ff; /* Adway info blue */

      &:hover {
        opacity: 80%;
      }
    }
  }

  .description-primary-link {
    a {
      color: var(--color-primary);
    }
  }

  [type="checkbox"].checkmark-black:checked {
    background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='black' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.207 4.793a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0l-2-2a1 1 0 011.414-1.414L6.5 9.086l4.293-4.293a1 1 0 011.414 0z'/%3e%3c/svg%3e");
  }
}

.title-wrapper .ais-Highlight-nonHighlighted,
.title-wrapper .ais-Highlight-highlighted {
  @apply text-xl;
}

.title-wrapper .ais-Highlight-highlighted {
  @apply bg-title-green;
}

.category-wrapper .ais-Highlight-nonHighlighted,
.category-wrapper .ais-Highlight-highlighted {
  @apply text-xs;
}

.category-wrapper .ais-Highlight-highlighted {
  @apply bg-category-orange;
}

.location-wrapper .ais-Highlight-nonHighlighted,
.location-wrapper .ais-Highlight-highlighted {
  @apply self-baseline text-sm font-normal;
}

.location-wrapper .ais-Highlight-highlighted {
  @apply bg-location-purple;
}

#phoneNumberPrefix {
  color: transparent !important;
  box-shadow: none !important;
  background: transparent !important;
}

.phoneSelect__indicator {
  border-right: 1px solid silver;
}

/*generated with Input range slider CSS style generator (version 20211225)
https://toughengineer.github.io/demo/slider-styler*/
input[type="range"].styled-slider {
  height: 3px;
  width: 100%;
  -webkit-appearance: none;
}

/*progress support*/
input[type="range"].styled-slider.slider-progress {
  --range: calc(var(--max) - var(--min));
  --ratio: calc((var(--value) - var(--min)) / var(--range));
  --sx: calc(0.5 * 1.5em + var(--ratio) * (100% - 1.5em));
}

/*webkit*/
input[type="range"].styled-slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  width: 1.5em;
  height: 1.5em;
  border-radius: 1em;
  background: #ffffff;
  border: 2px solid var(--color-primary);
  box-shadow: 0 0 2px black;
  margin-top: calc(3px * 0.5 - max(1.5em * 0.5, 2px));
}

input[type="range"].styled-slider::-webkit-slider-runnable-track {
  height: 3px;
  border: none;
  border-radius: 0.5em;
  background: #efefef;
  box-shadow: none;
}

input[type="range"].styled-slider::-webkit-slider-thumb:hover {
  cursor: pointer;
  background-color: var(--color-primary);
}

input[type="range"].styled-slider.slider-progress::-webkit-slider-runnable-track {
  background: linear-gradient(var(--color-primary), var(--color-primary)) 0 / var(--sx) 100% no-repeat, #efefef;
}

/*mozilla*/
input[type="range"].styled-slider::-moz-range-thumb {
  width: max(calc(1.5em - 2px - 2px), 0px);
  height: max(calc(1.5em - 2px - 2px), 0px);
  border-radius: 1em;
  background: #ffffff;
  border: 2px solid var(--color-primary);
  box-shadow: 0 0 2px black;
}

input[type="range"].styled-slider::-moz-range-track {
  height: 3px;
  border: none;
  border-radius: 0.5em;
  background: #efefef;
  box-shadow: none;
}

input[type="range"].styled-slider::-moz-range-thumb:hover {
  cursor: pointer;
  background-color: var(--color-primary);
}

input[type="range"].styled-slider.slider-progress::-moz-range-track {
  background: linear-gradient(var(--color-primary), var(--color-primary)) 0 / var(--sx) 100% no-repeat, #efefef;
}

/*ms*/
input[type="range"].styled-slider::-ms-fill-upper {
  background: transparent;
  border-color: transparent;
}

input[type="range"].styled-slider::-ms-fill-lower {
  background: transparent;
  border-color: transparent;
}

input[type="range"].styled-slider::-ms-thumb {
  width: 1.5em;
  height: 1.5em;
  border-radius: 1em;
  background: #ffffff;
  border: 2px solid var(--color-primary);
  box-shadow: 0 0 2px black;
  margin-top: 0;
  box-sizing: border-box;
}

input[type="range"].styled-slider::-ms-track {
  height: 3px;
  border-radius: 0.5em;
  background: #efefef;
  border: none;
  box-shadow: none;
  box-sizing: border-box;
}

input[type="range"].styled-slider::-ms-thumb:hover {
  cursor: pointer;
  background-color: var(--color-primary);
}

input[type="range"].styled-slider.slider-progress::-ms-fill-lower {
  height: 3px;
  border-radius: 0.5em 0 0 0.5em;
  margin: -undefined 0 -undefined -undefined;
  background: var(--color-primary);
  border: none;
  border-right-width: 0;
}

.rdp {
  margin: 0 !important;
}

.confetti {
  position: absolute;
  width: 5px;
  height: 5px;
  transform-origin: center bottom;
  animation: fall 4s linear infinite;
}

.confetti.square {
  clip-path: polygon(0% 0%, 0% 100%, 100% 100%, 100% 0%);
}
.confetti.triangle {
  clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
}

@keyframes fall {
  to {
    transform: translateY(100vh) rotate(720deg);
  }
}
